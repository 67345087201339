import React from 'react'

function Success() {
  return (
    <div><div class="alert alert-success" role="alert">
    This is a success alert—check it out!
  </div></div>
  )
}

export default Success